.dialogContent {
    @apply relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl w-full transition-all sm:my-8 sm:w-full sm:p-6;
}
.size-sm {
    @apply sm:max-w-lg;
}
.size-md {
    @apply sm:max-w-xl;
}
.size-lg {
    @apply sm:max-w-4xl;
}
.size-full {
    @apply sm:w-full;
}
.title {
    @apply text-2xl font-semibold leading-6 pr-4 mb-4;
}
