.arrow {
    @apply absolute bottom-1/2 translate-y-1/2 z-10 rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;;
}

.arrownext {
    @apply right-6;
}

.arrowprev {
    @apply left-6;
}
