.default {
    @apply divide-y divide-gray-300;
}
.default thead th {
    @apply py-3.5 pl-4 pr-3 sm:pl-6 lg:pl-8 bg-gray-100;
}
.default thead th .sort {
    @apply invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible;
}
.default tbody {
    @apply divide-y divide-gray-200 bg-white;
}
.default tbody td {
    @apply py-4 pl-4 pr-3 sm:pl-6 lg:pl-8 font-medium;
}
.default thead th:last-child,
.default tbody td:last-child {
    @apply py-4 pl-3 text-right sm:pr-6 lg:pr-8;
}

.simple {
}
.simple thead th {
    @apply text-xl pr-6 pb-4;
}
.simple tbody th:last-child {
    @apply pr-0;
}
.simple tbody tr {
    @apply cursor-pointer hover:bg-gray-300 transition-colors;
}
.simple tbody td {
    @apply text-base py-3 pr-6;
}
