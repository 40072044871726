.wrapper {
    @apply sm:col-span-3;
}

.wrapper-xs {
    @apply sm:col-span-1;
}

.wrapper-s {
    @apply sm:col-span-2;
}

.wrapper-l {
    @apply sm:col-span-4;
}

.wrapper-xl {
    @apply sm:col-span-6;
}

.wrapper-full {
    @apply col-span-full;
}

.label {
    @apply block text-sm font-medium leading-6 text-gray-900;
}

.innerControl {
    @apply mt-2 relative;
}

.innerControl > :is(input, textarea, select) {
    @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}


.innerControlError > :is(input, textarea, select) {
    @apply block w-full rounded-md border-0 py-1.5 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6;
}

.messageInfo {
    @apply mt-3 text-sm leading-6 text-gray-600;
}

.messageError {
    @apply mt-3 text-sm leading-6 text-red-600;
}
