.slide {
    @apply rounded-xl overflow-hidden;
}
.navSlider {
    @apply mt-2 -mx-2;
}
.navSlider [data-index] {
    @apply px-2;
}
.navSlider [data-index] > div {
    @apply rounded-md overflow-hidden;
}

