.button {
    @apply font-semibold rounded-md shadow-sm transition-colors;
}
.button.primary {
    @apply text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-400;
}
.button.secondary {
    @apply bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
}
.button.success {
    @apply bg-green-100 hover:bg-green-400;
}
.button.danger {
    @apply bg-red-100 hover:bg-red-400;
}
.button.outline {
    @apply border-none bg-transparent shadow-none !p-0;
}
.button.grey {
    @apply bg-gray-100 text-gray-700 hover:text-gray-700;
}
.button.greyOpacity {
    @apply bg-gray-500 bg-opacity-50 text-white;
}
.button.size-sm {
    @apply text-sm px-2.5 py-1.5;
}
.button.size-md {
    @apply text-sm px-3 py-2;
}
.button.size-lg {
    @apply text-sm px-3.5 py-2.5;
}
.button.size-xl {
    @apply text-xl px-5 py-3;
}
.button.round {
    @apply w-8 h-8 rounded-full flex items-center justify-center p-0;
}
.button.icon {
    @apply inline-flex items-center gap-x-1.5;
}
.button.soft {
    @apply rounded bg-indigo-50 text-indigo-600 shadow-sm hover:bg-indigo-100;
}
.button.loading svg + svg {
    @apply hidden;
}
